import React, { useEffect, useRef, Fragment, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import StepAction from "./StepAction";
import { Steps, Icon, Typography, Result } from "antd";

import {
  accessErp,
  setFirstAccess,
  clearToken,
  setCurrentStep,
  saveFirstAccess
} from "../../actions";

import Container from "../AppLayout/Container";
import FirstAccessForm from "./FirstAccessForm";
import EmailConfiguration from "./EmailConfiguration";

const { Step } = Steps;
const { Title, Paragraph } = Typography;

const FirstAccess = ({
  saveFirstAccessAction,
  setFirstAccessAction,
  accessErpAction,
  clearTokenAction,
  setCurrentStepAction,
  currentStep,
  authError,
  contractAdministrators
}) => {
  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  useEffect(() => {
    setFirstAccessAction(true);

    return function cleanup() {
      setFirstAccessAction(false);
    };
  }, [setFirstAccessAction]);

  const onSubmit = values => {
    setLoading(true);
    accessErpAction(values).then(() => setLoading(false));
  };

  const steps = [
    {
      title: "Informações",
      icon: <Icon type="solution" />,
      question: "Nova forma de acesso a Área do Cliente da Tron",
      detailItems: [
        "O acesso mudou e a partir de agora não será por Código de Cliente, você deverá acessar com seu e-mail. Se essa for a primeira vez que você tenta acessar nossa plataforma preencha com os dados antigos e vamos confirmar algumas informações com você.",
        "É importante que esse procedimento seja realizado pelo administrador do contrato ou supervisionado pelo mesmo."
      ],
      content: props => false
    },
    {
      title: "Login",
      icon: <Icon type="user" />,
      question: "Insira seus dados de acesso",
      content: () => (
        <Fragment>
          {authError && (
            <div className="alert alert-danger" role="alert">
              {authError}
              {renderContractAdministrators()}
            </div>
          )}

          <FirstAccessForm ref={formRef} onSubmit={onSubmit} />
        </Fragment>
      )
    },
    {
      title: "Autorizações",
      icon: <Icon type="audit" />,
      question: "Quais usuários terão acesso?",
      detailItems: [
        "Selecione os usuários que poderão acessar e informe o tipo de usuário.",
        "É obrigatório que pelo menos um seja definido como administrador."
      ],
      content: () => (
        <Fragment>
          {authError && (
            <div className="alert alert-danger" role="alert">
              {authError}
            </div>
          )}

          <EmailConfiguration />
        </Fragment>
      )
    },
    {
      title: "Conclusão",
      icon: <Icon type="smile-o" />,
      content: () => (
        <Result
          status="success"
          title="Cadastro realizado com sucesso"
          subTitle="Caso o usuário ainda não esteja em nossa base de dados em alguns minutos ele receberá um email para confirmar seu cadastro."
        >
          <div className="desc">
            <Title level={4}>Instruções com os próximos passos:</Title>
            <Paragraph>
              <Icon type="check-circle" /> Se o usuário já tiver cadastro na
              plataforma poderá efetuar o login com os mesmos dados já
              existentes.
            </Paragraph>
            <Paragraph>
              <Icon type="check-circle" /> Os usuários informados que não
              possuem cadastro no portal de login irá receber um email para
              criar uma senha.
            </Paragraph>
          </div>
        </Result>
      )
    }
  ];

  const renderContractAdministrators = () => {
    return contractAdministrators.map(email => <div key={email}>{email}</div>);
  };

  const nextStep = () => {
    if (currentStep === 1) {
      formRef.current.handleSubmit();
    } else if (currentStep === 2) {
      setLoading(true);
      saveFirstAccessAction().then(() => setLoading(false));
    } else setCurrentStepAction(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep === 1 || currentStep === 2) clearTokenAction();

    setCurrentStepAction(currentStep - 1);
  };

  return (
    <Container>
      <div className="card">
        <div className="card-body">
          <Steps current={currentStep}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} icon={item.icon} />
            ))}
          </Steps>
          <div className="steps-content">
            {steps[currentStep].question && (
              <div className="desc">
                <Title level={3}>{steps[currentStep].question}</Title>
                {steps[currentStep].detailItems &&
                  steps[currentStep].detailItems.map((items, index) => (
                    <Paragraph key={index}>
                      <Icon type="check-circle" /> {items}
                    </Paragraph>
                  ))}
              </div>
            )}
            {steps[currentStep].content()}
          </div>
          <StepAction
            loading={loading}
            currentStep={currentStep}
            nextStep={() => nextStep()}
            prevStep={prevStep}
            stepsSize={steps.length}
          />
        </div>
      </div>

      <ul className="links-footer">
        <li>
          <Link to="/login" className="btn btn-block btn-link">
            Ir para o Login
          </Link>
        </li>
      </ul>
    </Container>
  );
};

const mapStateToProps = state => ({
  currentStep: state.erp.currentStep,
  authError: state.erp.authError,
  contractAdministrators: state.erp.contractAdministrators
});

const mapDispatchToProps = {
  accessErpAction: accessErp,
  clearTokenAction: clearToken,
  setFirstAccessAction: setFirstAccess,
  setCurrentStepAction: setCurrentStep,
  saveFirstAccessAction: saveFirstAccess
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstAccess);
