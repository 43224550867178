import "./DirectAccess.css";

import PropTypes from "prop-types";
import qs from "query-string";
import React, { useEffect } from "react";

import tokenIsExpired from "../../utils/tokenIsNotExpired";
import Portal from "../Portal";
import { connect } from "react-redux";

const propTypes = {
  location: PropTypes.shape({}),
  history: PropTypes.shape({})
};

const defaultProps = {
  location: {},
  history: {}
};

const DirectAccess = ({ location, history, token }) => {
  const { accessKey } = qs.parse(location.search);

  useEffect(() => {
    if (tokenIsExpired(accessKey)) return history.push("/login");

    if (token) return history.push("/home");

    sessionStorage.setItem("token", accessKey);
    return window.location.reload();
  });

  return (
    <Portal>
      <div className="portal">
        <div>Loading...</div>
      </div>
    </Portal>
  );
};

const mapStateToProps = state => ({
  token: state.auth.token
});

DirectAccess.propTypes = propTypes;
DirectAccess.defaultProps = defaultProps;

export default connect(mapStateToProps)(DirectAccess);
