import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import qs from "query-string";

import { forgotPassword } from "../../actions";
import Container from "../AuthLayout/Container";
import ForgotPasswordForm from "./ForgotPasswordForm";

class ForgotPassword extends React.Component {
  _obURL = process.env.REACT_APP_ONBOARDING_ENDPOINT;
  
  constructor(props) {
    super(props);

    const { module } = qs.parse(props.location.search);
    console.log(module);

    this.state = {
      isEmailSent: false,
      module: (module || "SE").trim().toLocaleUpperCase(),
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.renderText = this.renderText.bind(this);
  }

  componentWillUmount() {
    this.setState({
      isEmailSent: false,
      module: "SE",
    });
  }

  onSubmit(formValues) {
    const { forgotPassword } = this.props;
    const { module } = this.state;

    forgotPassword(formValues, module, () => this.setState({ isEmailSent: true }));
  }

  renderText() {
    const { isEmailSent } = this.state;
    return !isEmailSent ? (
      <p>
        Insira o <b>e-mail</b> que você utiliza para acessar os produtos da
        Tron. Nós mandaremos um link para você redefinir sua senha.
      </p>
    ) : (
      <p>
        Foi enviado um e-mail para você com instruções de como redefinir sua
        senha.
      </p>
    );
  }

  render() {
    const { forgetEmailMessage } = this.props;
    const { isEmailSent, module } = this.state;

    return (
      <Container subTitle={this.renderText}>
        {forgetEmailMessage && (
          <div className="alert alert-danger" role="alert">
            {forgetEmailMessage}
          </div>
        )}
        {!isEmailSent ? (
          <React.Fragment>
            <ForgotPasswordForm onSubmit={this.onSubmit} />
            <ul className="links-footer">
              <li>
                {
                  module === "OB" 
                    ? <a href={this._obURL} rel="noopener noreferrer">Voltar</a>
                    : <Link to="/login"><p>Voltar</p></Link>
                }
              </li>
            </ul>
            <div className="texto-footer fs-13">
              <span>Se ainda precisa de ajuda, contate o Suporte da Tron.</span>
            </div>
          </React.Fragment>
        ) : (
          <ul className="links-footer">
            <li>
              {
                module === "OB" 
                  ? <a href={this._obURL} rel="noopener noreferrer" className="btn btn-block btn-link">Voltar</a>
                  : <Link to="/login" className="btn btn-block btn-link">Voltar</Link>
              }
            </li>
          </ul>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forgetEmailMessage: state.auth.forgetEmailMessage
});

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
