import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import { login } from "../../actions";
import Container from "../AuthLayout/Container";
import LoginForm from "./LoginForm";

const Login = ({ authError, loginAction, history, location }) => {
  const onSubmit = (formValues, cb) => {
    loginAction(formValues, location.search, () => history.push("/home")).then(
      cb
    );
  };

  return (
    <Container>
      {authError && (
        <div className='alert alert-danger' role='alert'>
          {authError}
        </div>
      )}
      <LoginForm onSubmit={onSubmit} />
      <ul className='links-footer'>
        <li>
          <Link to='/informe-email'>
            <p> Esqueceu sua senha?</p>
          </Link>
        </li>
      </ul>

      <p className='text-center' style={{ marginTop: "1em" }}>
        OU
      </p>

      <ul className='links-footer'>
        <li>
          <button
            type='button'
            className='btn btn-block btn-warning'
            onClick={() => history.push("/primeiro-acesso")}
          >
            Primeiro acesso com código de cliente
          </button>
        </li>
      </ul>
      {/* <div className="texto-footer">
        <span>Termos de Serviço | Política de Privacidade</span>
      </div> */}
    </Container>
  );
};

const mapStateToProps = state => ({ authError: state.auth.authError });

export default withRouter(
  connect(mapStateToProps, { loginAction: login })(Login)
);
