import * as Yup from "yup";

export const NewPasswordSchema = Yup.object().shape({
  senha: Yup.string()
    .required("Campo requerido.")
    .min(6, ({ min }) => `A senha deve possuir no mínimo ${min} caracteres.`)
    .matches(/\d/, 'A senha deve conter ao menos um número.'),
  senhaConfirmar: Yup.string()
    .test(
      "password-match",
      "As senhas informadas não coincidem.",
      function checkPasswordMatch(value) {
        const { senha } = this.parent;
        return senha === value;
      }
    )
    .required("Campo requerido.")
});

export const ChangePasswordSchema = Yup.object().shape({
  senhaAtual: Yup.string()
    .required("Campo requerido."),
  senha: Yup.string()
    .required("Campo requerido.")
    .min(6, ({ min }) => `A nova senha deve possuir no mínimo ${min} caracteres.`)
    .matches(/\d/, 'A senha deve conter ao menos um número.'),
  senhaConfirmar: Yup.string()
    .test(
      "password-match",
      "As senhas informadas não coincidem.",
      function checkPasswordMatch(value) {
        const { senha } = this.parent;
        return senha === value;
      }
    )
    .required("Campo requerido.")
});
