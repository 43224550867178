import Swal from 'sweetalert2';

import sentinela from '../api/sentinela';
import DEFAULT_ROUTE_CONFIG from '../utils/DefaultRouteConf';
import RouteComponents from './../utils/RouteComponents';
import {
    CLEAR_APP_ROUTES_MENUS,
    FETCH_APP_ROUTES_MENUS_FAIL,
    FETCH_APP_ROUTES_MENUS_SUCCESS,
    ROT_UPDATE_ROUTES_FAIL,
    ROT_UPDATE_ROUTES_SUCCESS,
} from './types';

const ToastMixin = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
});

const ROTAS_DEFAULT = [
    {
        ...DEFAULT_ROUTE_CONFIG,
        path: '/',
        component: RouteComponents['/'],
    },
    {
        ...DEFAULT_ROUTE_CONFIG,
        path: '/alterar-senha',
        component: RouteComponents['/alterar-senha'],
    },
    {
        ...DEFAULT_ROUTE_CONFIG,
        path: '/acesso-nao-autorizado',
        component: RouteComponents['/acesso-nao-autorizado'],
    },
    {
        ...DEFAULT_ROUTE_CONFIG,
        path: '/not-found',
        component: RouteComponents['/not-found'],
    },
    {
        ...DEFAULT_ROUTE_CONFIG,
        path: '/autorizar',
        component: RouteComponents['/autorizar'],
    },
    {
        ...DEFAULT_ROUTE_CONFIG,
        path: '/configuracoes',
        component: RouteComponents['/configuracoes'],
    },
    {
        ...DEFAULT_ROUTE_CONFIG,
        path: '/logout',
        component: RouteComponents['/logout'],
    },
];

export const fetchRoutesMenus = () => async dispatch => {
    try {
        const { data } = await sentinela.get(`/rotas/rotas-permitidas/8`);
        const routesAndMenus = {
            ...data,
            rotas: [
                ...ROTAS_DEFAULT,
                ...data.rotas.map(route => ({
                    ...route,
                    component: RouteComponents[route.path],
                    exact: true,
                })),
            ],
        };

        dispatch({
            type: FETCH_APP_ROUTES_MENUS_SUCCESS,
            payload: routesAndMenus,
        });
    } catch (err) {
        dispatch({ type: FETCH_APP_ROUTES_MENUS_FAIL });
    }
};

export const clearRoutesMenus = () => {
    return {
        type: CLEAR_APP_ROUTES_MENUS,
    };
};

export const atualizarRotas = () => async dispatch => {
    try {
        await sentinela.post(`/rotas`);

        ToastMixin.fire({
            type: 'success',
            title: 'Atualização de rotas realizada com sucesso.',
        });

        dispatch({ type: ROT_UPDATE_ROUTES_SUCCESS });
    } catch (error) {
        ToastMixin.fire({
            type: 'error',
            title: 'Não foi possível atualizar as rotas.',
        });

        dispatch({ type: ROT_UPDATE_ROUTES_FAIL });
    }
};
