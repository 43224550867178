import axios from "axios";
import { FETCH_BANNERS_SUCCESS, FETCH_BANNERS_FAIL } from "./types";

const BASE_URL = "https://tron.com.br/cliente/wp-json/wp/v2";

export const fetchBanners = () => async dispatch => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/banners-login?page=1&per_page=2&_embed`
    );

    const bannerList = data.map(item => {
      return {
        redirectLink: item.acf && item.acf.link ? item.acf.link : "",
        imagePath: item["_embedded"]["wp:featuredmedia"][0].source_url
      };
    });

    dispatch({ type: FETCH_BANNERS_SUCCESS, payload: bannerList });
  } catch (err) {
    dispatch({ type: FETCH_BANNERS_FAIL });
  }
};
