import React from "react";
import Banner from "./Banner";

const Container = ({ children, subTitle }) => (
  <section className="auth wrap container-fluid">
    <div className="text-center">
      <div className="row">
        <div className="col-xl-3 offset-xl-2 order-xl-2 text-left">
          <div className="wrap-form-login">
            <div className="text-center">
              <img
                src="assets/img/logo-tron-login.png"
                alt="logo_tron"
                className="logo-login"
              />
              {subTitle && subTitle()}
            </div>
            {children}
          </div>
        </div>
        <Banner />
      </div>
    </div>
  </section>
);

export default Container;
