import {
  FETCH_TENANT_SUCCESS,
  FETCH_TENANTS_MODULES_BY_SPECIFICATION_SUCCESS,
  FETCH_TENANTS_SUCCESS
} from "../actions/types";

const INITIAL_STATE = {
  contractTenants: [],
  tenantModules: [],
  tenantData: {}
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TENANTS_SUCCESS:
      return { ...state, contractTenants: payload };
    case FETCH_TENANTS_MODULES_BY_SPECIFICATION_SUCCESS:
      return { ...state, tenantModules: payload };
    case FETCH_TENANT_SUCCESS:
      return { ...state, tenantData: payload };
    default:
      return state;
  }
};
