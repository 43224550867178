import Swal from "sweetalert2";
import { history } from "../history";
import { createSuccessNotification, createErrorNotification, createWarningNotification, handleBackendErrorResponse } from "../utils/Notify";
import _ from "lodash";
import sentinela from "../api/sentinela";
import { fetchCurrentUser, fetchModulosUser } from "./authActions";
import {
  CREATE_TENANT_FAIL,
  CREATE_TENANT_SUCCESS,
  FETCH_TENANT_FAIL,
  FETCH_TENANT_SUCCESS,
  FETCH_TENANTS_FAIL,
  FETCH_TENANTS_MODULES_BY_SPECIFICATION_FAIL,
  FETCH_TENANTS_MODULES_BY_SPECIFICATION_SUCCESS,
  FETCH_TENANTS_SUCCESS,
  UPDATE_TENANT_FAIL,
  UPDATE_TENANT_SUCCESS,
  TENANT_DELETE_SUCCESS,
  TENANT_DELETE_FAIL,
} from "./types";
import { addUserToTenant } from "./userActions";

export const createTenant = (formValues, cb) => async (dispatch, getState) => {
  const {
    auth: { currentUser },
  } = getState();

  try {
    const { value } = await Swal.fire({
      title: "Você tem certeza que deseja criar?",
      text: "Ao confirmar a base será criada com os dados informados.",
      type: "warning",
      focusCancel: true,
      showCancelButton: true,
      confirmButtonColor: "#18a689",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, pode criar!",
    });

    if (!value) return;

    const tenant = {
      ...formValues,
      modulo: Number(formValues.modulo),
      nomeRazaoSocial: formValues.inscricao
        ? `${formValues.inscricao} - ${formValues.nomeRazaoSocial}`
        : formValues.nomeRazaoSocial,
    };

    tenant.email = tenant.email ? tenant.email : currentUser.nome;
    tenant.inscricao = tenant.inscricao.replace(/[^\d]+/g, "");

    await sentinela.post("/clientes", tenant);
    dispatch({ type: CREATE_TENANT_SUCCESS });

    await dispatch(fetchCurrentUser());
    await dispatch(fetchModulosUser(true));

    if (cb && typeof cb === "function") cb(true);
  } catch ({ response }) {
    if (cb && typeof cb === "function") cb(false, response && response.data ? response.data : null);
    dispatch({ type: CREATE_TENANT_FAIL });
  }
};

export const fetchTenants = (cb) => async (dispatch) => {
  try {
    const { data } = await sentinela.get("/clientes");
    dispatch({ type: FETCH_TENANTS_SUCCESS, payload: data });

    if (cb && typeof cb === "function") cb(data);
  } catch (error) {
    dispatch({ type: FETCH_TENANTS_FAIL });
  }
};

export const fetchTenantModulesBySpecification =
  (especificao) => async (dispatch, getState) => {
    const {
      currentUser: { contratoAtual },
    } = getState().auth;

    try {
      const { data } = await sentinela.get(
        `/contratos/${contratoAtual.contratoId}/quantidades/${especificao}`
      );

      dispatch({
        type: FETCH_TENANTS_MODULES_BY_SPECIFICATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: FETCH_TENANTS_MODULES_BY_SPECIFICATION_FAIL });
    }
  };

export const fetchTenant = (id) => async (dispatch) => {
  try {
    const { data } = await sentinela.get(`/clientes/gerenciar/${id}`);

    const regex = /DB-(GC|TS|CN|LR|BX)-[0-9]/i;

    const tenantData = {
      ...data,
      usuarios: data.usuarios.filter((usuario) => !regex.test(usuario.email)),
    };

    dispatch({ type: FETCH_TENANT_SUCCESS, payload: tenantData });
  } catch (error) {
    dispatch({ type: FETCH_TENANT_FAIL });
  }
};

export const updateTenant = (data, usuarios, cb) => async (dispatch) => {
  try {
    await sentinela.patch(`/clientes/${data.id}`, { nome: data.nome });

    dispatch({ type: UPDATE_TENANT_SUCCESS });

    if (usuarios.length > 0) {
      for (const usuario of usuarios) await dispatch(addUserToTenant(usuario));
    }

    if (cb && typeof cb === "function") cb(true);
  } catch (error) {
    dispatch({ type: UPDATE_TENANT_FAIL });
  }
};

export const deletarTenant = (tenantId) => async (dispatch, getState) => {
  try {
    const { value } = await Swal.fire({
      title: "Deseja realmente excluir este cliente?",
      text: "Uma vez excluído, a operação se torna irreversível e o cliente não poderá ter acesso ao ambiente Tron.",
      type: "warning",
      focusCancel: true,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#18a689",
      confirmButtonText: "Sim, pode excluir!",
      cancelButtonText: "Cancelar",
    });

    if (!value) return;
    await sentinela.delete(`/tenants/exclusao-logica/${tenantId}`);
    const {
      tenants: { contractTenants },
    } = getState();

    const tenants = [...contractTenants];

    _.remove(tenants, (tenant) => tenant.id === tenantId);

    dispatch({ type: FETCH_TENANTS_SUCCESS, payload: tenants });

    createSuccessNotification('Cliente excluído com sucesso!');
    dispatch({ type: TENANT_DELETE_SUCCESS });
  } catch (error) {
    if (error.response) {
      handleBackendErrorResponse(error.response);
    } else {
      createErrorNotification('Ocorreu um erro durante a exclusão do cliente!');
    }
    dispatch({ type: TENANT_DELETE_FAIL });
  }
};

export const fetchTenantIncricao = (inscricao) => async (dispatch) => {
  try {
    let newData;
    if (!inscricao || inscricao.length < 14)
      return createErrorNotification("Inscrição inválida");

    const inscricaoFormatada = inscricao.replace(/[^0-9]+/g, "");
    const { data } = await sentinela.get(
      `/clientes/inscricao?inscricao=${inscricaoFormatada}`
    );
    if (data) {
      let emailsAtualizados = [];
      if (data.usuariosContratos.length > 0) {
        emailsAtualizados = data.usuariosContratos.filter(
          (u) => !u.email.includes("DB-")
        );
      }

      newData = {
        ...data,
        usuariosContratos: emailsAtualizados,
      };
      dispatch({ type: FETCH_TENANT_SUCCESS, payload: newData });
    } else createWarningNotification("Não foi encontrado nenhum cliente", "warning");
  } catch (error) {
    createErrorNotification("Error ao buscar Inscrição");
    dispatch({ type: FETCH_TENANT_FAIL });
  }
};

export const updateContratTenant =
  (tenantData, contractDestin) => async (dispatch) => {
    const { codigoClienteNovo, emailContadorNovo } = contractDestin;

    const payload = {
      ...tenantData,
      codigoClienteNovo,
      emailContadorNovo,
    };

    try {
      sentinela.post(`/contratos/trocar-contratos`, payload);
      createSuccessNotification("Foi feito a troca de contratos com Sucesso!");
      history.push("/");
    } catch (error) {
      createErrorNotification("Código do cliente ou email inválidos ou não encontrados");
      dispatch({ type: FETCH_TENANT_FAIL });
    }
  };
