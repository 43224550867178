import { ErrorMessage, Field, Formik } from "formik";
import React from "react";

import { AuthSchema } from "../../utils/validations";

const LoginForm = ({ onSubmit }) => {
  const handleSubmit = (values, { setSubmitting }) => {
    onSubmit(values, () => setSubmitting(false));
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ usuario: "", senha: "" }}
      validationSchema={AuthSchema}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <div className='form-group'>
            <label htmlFor='usuario'>Endereço de e-mail:</label>
            <Field
              name='usuario'
              type='input'
              placeholder='Digite seu e-mail'
              className='form-control tron-input'
            />
            <ErrorMessage
              className='validation-errors pt-2'
              component='div'
              name='usuario'
            />
          </div>

          <div className='form-group'>
            <label htmlFor='senha'>Senha:</label>
            <Field
              name='senha'
              type='password'
              placeholder='Digite sua senha'
              className='form-control tron-input'
            />
            <ErrorMessage
              className='validation-errors pt-2'
              component='div'
              name='senha'
            />
          </div>

          <button
            type='submit'
            className='btn btn-block btn-tron'
            disabled={props.isSubmitting}
          >
            {props.isSubmitting && (
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              />
            )}

            {props.isSubmitting ? " Entrando..." : "Entrar"}
          </button>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
