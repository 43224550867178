import React, { Suspense } from "react";
import { connect } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";

import ContentWrapper from "../ContentWrapper";
import RouteWithSubRoutes from "./RouteWithSubRoutes";
import Loading from "../Loading";
import { Redirect } from "react-router";

class Router extends React.PureComponent {
  constructor(props) {
    super(props);
    this.checkUserAuthenticated = this.checkUserAuthenticated.bind(this);
  }

  checkUserAuthenticated(props) {
    const { token } = this.props;
    var redirect = token ? "/not-found" : "/login";
    return <Redirect to={redirect} />;
  }

  render() {
    const { routes } = this.props;
    return (
      <Suspense fallback={<Loading />}>
        <HashRouter>
          <ContentWrapper>
            <Switch>
              {routes.map((route, index) => (
                <RouteWithSubRoutes key={index} {...route} />
              ))}
              <Route render={this.checkUserAuthenticated} />
            </Switch>
          </ContentWrapper>
        </HashRouter>
      </Suspense>
    );
  }
}

const mapStateToProps = state => ({
  routes: state.routes.appRoutes,
  token: state.auth.token
});

export default connect(mapStateToProps)(Router);
