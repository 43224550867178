import * as Yup from "yup";

export const ChangeEmailSchema = Yup.object().shape({
  usuario: Yup.string()
    .email("O e-mail informado é inválido.")
    .required("Campo requerido."),
  novoUsuario: Yup.string()
    .email("O novo e-mail informado é inválido.")
    .required("Campo requerido.")
});
