import React, { forwardRef } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import InputMask from "react-input-mask";

import { AuthFirstAccessSchema } from "../../utils/validations";

const FirstAccessForm = forwardRef((props, ref) => (
  <Formik
    onSubmit={props.onSubmit}
    initialValues={{ codigoCliente: "", senhaSite: "" }}
    ref={ref}
    validationSchema={AuthFirstAccessSchema}
  >
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="codigoCliente">Código de Cliente</label>
          <Field name="codigoCliente" placeholder="Digite código de cliente">
            {({ field }) => (
              <InputMask
                className="form-control tron-input"
                maskChar={null}
                mask={"99-99999"}
                {...field}
              />
            )}
          </Field>
          <ErrorMessage
            className="validation-errors pt-2"
            component="div"
            name="codigoCliente"
          />
        </div>

        <div className="form-group">
          <label htmlFor="senhaSite">Senha:</label>
          <Field
            name="senhaSite"
            type="password"
            placeholder="Digite sua senha"
            className="form-control tron-input"
          />
          <ErrorMessage
            className="validation-errors pt-2"
            component="div"
            name="senhaSite"
          />
        </div>
      </form>
    )}
  </Formik>
));

export default FirstAccessForm;
