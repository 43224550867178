import React, { Fragment } from "react";

import { connect } from "react-redux";
import { setGridInstance, addNewEmail } from "../../actions";

import DataGrid, {
  Column,
  Selection,
  Lookup,
  Editing,
  RequiredRule,
  EmailRule
} from "devextreme-react/data-grid";

import NewEmailForm from "./NewEmailForm";

const EmailConfiguration = ({
  setGridInstanceAction,
  addNewEmailAction,
  emails
}) => (
  <Fragment>
    <DataGrid
      dataSource={emails}
      showRowLines
      showBorders
      columnAutoWidth
      keyExpr="email"
      onInitialized={({ component }) => setGridInstanceAction(component)}
    >
      <Selection
        mode="multiple"
        selectAllMode="allPages"
        showCheckBoxesMode="always"
      />
      <Editing mode="cell" allowUpdating />

      <Column dataField="nome" allowEditing={false} />
      <Column dataField="email" allowEditing={false}>
        <RequiredRule />
        <EmailRule />
      </Column>
      <Column dataField="tipo" caption="Tipo de Usuário" showEditorAlways>
        <Lookup
          dataSource={[
            { id: 1, name: "Administrador" },
            { id: 2, name: "Colaborador" }
          ]}
          displayExpr="name"
          valueExpr="id"
        />
        <RequiredRule />
      </Column>
    </DataGrid>

    <NewEmailForm onSubmit={addNewEmailAction} />
  </Fragment>
);

const mapStateToProps = state => ({
  emails: state.erp.emails
});

const mapDispatchToProps = {
  setGridInstanceAction: setGridInstance,
  addNewEmailAction: addNewEmail
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailConfiguration);
