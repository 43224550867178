import * as Yup from "yup";

export const NewUserGroupSchema = Yup.object().shape({
  descricao: Yup.string()
    .required("Campo requerido.")
    .max(80, ({ max }) => `A descrição não pode exceder ${max} caractes.`),
  modulos: Yup.array().required("Campo requerido.")
});

export const NewVisibilityProfileSchema = Yup.object().shape({
  descricao: Yup.string()
    .required("Campo requerido.")
    .max(80, ({ max }) => `A descrição não pode exceder ${max} caractes.`)
});
