import {
  ERP_REQUEST_GET_EMAILS_ERP_SUCCESS,
  ERP_REQUEST_GET_EMAILS_ERP_FAIL,
  ERP_SET_FIRST_ACCESS,
  ERP_SET_CLEAR_TOKEN,
  ERP_CHANGE_CURRENT_STEP,
  ERP_SET_GRID_INSTANCE,
  ERP_SET_TOKEN_SENTINELA,
  ERP_SET_ADMINISTRATOR_EMAILS,
  ERP_SET_CODIGO_CLIENTE,
  ERP_SET_CONTRACT_ADMINISTRATORS
} from "./types";

import Swal from "sweetalert2";
import sentinela from "../api/sentinela";
import axios from "axios";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const MensagemERP = {
  ClienteExistente:
    "Esse código de cliente já foi migrado! Entre em contato com algum dos administradores abaixo para realizar seu cadastro:"
};

export const setGridInstance = payload => ({
  type: ERP_SET_GRID_INSTANCE,
  payload
});

export const setTokenSentinela = payload => ({
  type: ERP_SET_TOKEN_SENTINELA,
  payload
});

export const setCodigoCliente = payload => ({
  type: ERP_SET_CODIGO_CLIENTE,
  payload
});

export const clearToken = () => ({
  type: ERP_SET_CLEAR_TOKEN
});

export const setFirstAccess = payload => ({
  type: ERP_SET_FIRST_ACCESS,
  payload
});

export const setCurrentStep = payload => ({
  type: ERP_CHANGE_CURRENT_STEP,
  payload
});

export const setEmailsSuccess = payload => ({
  type: ERP_REQUEST_GET_EMAILS_ERP_SUCCESS,
  payload
});

export const setAdministratorEmails = payload => ({
  type: ERP_SET_ADMINISTRATOR_EMAILS,
  payload
});

export const setEmailsFail = payload => ({
  type: ERP_REQUEST_GET_EMAILS_ERP_FAIL,
  payload
});

export const setContractAdministrators = payload => ({
  type: ERP_SET_CONTRACT_ADMINISTRATORS,
  payload
});

export const accessErp = credenciais => async (dispatch, getState) => {
  try {
    dispatch(setContractAdministrators([]));

    const {
      erp: { currentStep }
    } = getState();

    const {
      data: { emails, token, razaoSocial }
    } = await sentinela.post("/autenticacao/login-erp", credenciais);

    var listaEmail = emails.map(item => ({
      ...item,
      tipo: item.administrador ? 1 : 2
    }));

    dispatch(setTokenSentinela(token));
    dispatch(
      setCodigoCliente({
        codigocliente: credenciais.codigoCliente,
        razaoSocial
      })
    );
    dispatch(setEmailsSuccess(listaEmail));
    dispatch(setAdministratorEmails(emails.filter(item => item.administrador)));
    dispatch(setCurrentStep(currentStep + 1));
  } catch (error) {
    const {
      response: { data }
    } = error;
    let errorMessage = error.message;

    if ("key" in data) {
      errorMessage = MensagemERP[data.key] || data.mensagem;

      if (MensagemERP[data.key])
        dispatch(setContractAdministrators(data.mensagem.split(",")));
    }

    dispatch(setEmailsFail(errorMessage));
  }
};

export const addNewEmail = formValues => (dispatch, getState) => {
  const {
    erp: { emails }
  } = getState();

  if (!emails.some(e => e.email === formValues.email))
    dispatch(setEmailsSuccess([...emails, ...[{ ...formValues, tipo: 2 }]]));
  else dispatch(setEmailsFail("Email informado já está na lista"));
};

export const saveFirstAccess = () => async (dispatch, getState) => {
  try {
    const {
      erp: {
        tokenSentinela,
        dadosContrato,
        gridInstance,
        currentStep,
        administratorEmails
      }
    } = getState();

    const usuariosSelecionados = gridInstance.getSelectedRowsData();

    if (usuariosSelecionados.length > 0) {
      const hasAdministrator = usuariosSelecionados.some(e => e.tipo === 1);

      if (hasAdministrator) {
        const { value } = await Swal.fire({
          title: "Você tem certeza?",
          text:
            "Esse processo não poderá ser desfeito. Ao clicar em sim você confirma que tem ciência de todas as informações passadas.",
          type: "warning",
          focusCancel: true,
          showCancelButton: true,
          confirmButtonColor: "#18a689",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim, pode criar!"
        });

        if (!value) return;

        const administradores = administratorEmails.map(item => item.email);

        const data = {
          ...dadosContrato,
          administradores,
          usuarios: usuariosSelecionados
        };

        var config = {
          headers: {
            Authorization: `Bearer ${tokenSentinela}`
          }
        };

        await axios.post(
          `${baseUrl}/contratos/migracao-usuarios`,
          data,
          config
        );

        dispatch(setCurrentStep(currentStep + 1));
      } else
        dispatch(
          setEmailsFail("Obrigatório informar pelo menos um administrador.")
        );
    } else dispatch(setEmailsFail("Nenhum usuário selecionado."));
  } catch (error) {
    const { response } = error;
    const errorMessage = response ? response.data.mensagem : error.message;

    dispatch(setEmailsFail(errorMessage));
  }
};
