import { combineReducers } from "redux";

import authReducer from "./authReducer";
import bannerReducer from "./bannerReducer";
import erpReducer from "./erpReducer";
import gerenciamentoReducer from "./gerenciamentoReducer";
import moduleReducer from "./moduleReducer";
import routeReducer from "./routeReducer";
import tenantReducer from "./tenantReducer";
import userGroupReducer from "./userGroupReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  erp: erpReducer,
  routes: routeReducer,
  modules: moduleReducer,
  tenants: tenantReducer,
  users: userReducer,
  userGroups: userGroupReducer,
  banners: bannerReducer,
  gerenciamento: gerenciamentoReducer,
});

export default rootReducer;
