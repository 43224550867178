import axios from "axios";
import Swal from "sweetalert2";
import sentinela from "../api/sentinela";

const ToastMixin = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 5000,
});

export const getConfig = () => async () => {
  try {
    const { data } = await sentinela.get(`area-clientes/configuracao`);
    return data;
  } catch (error) {
    ToastMixin.fire({
      type: "error",
      title: "Não foi possível buscar suas configurações.",
    });
  }
};

export const fetchHistoric = (filter) => async () => {
  try {
    const params = {
      filtro: JSON.stringify(filter),
    };

    const { data } = await sentinela.get(`area-clientes/historico`, { params });
    if (data) return data.result;
    return [];
  } catch (error) {
    ToastMixin.fire({
      type: "error",
      title: "Não foi possível buscar seus históricos.",
    });
  }
};

export const uploadCertificate = (certificate) => async () => {
  try {
    const formData = new FormData();
    formData.append("inscricaoEmpresa", certificate.enrollment);
    formData.append("senha", certificate.passCertificate);
    formData.append("arquivo", certificate.file);
    await sentinela.post(`area-clientes/certificado/upload`, formData);
    return true;
  } catch (error) {
    console.log(error, "error");
    ToastMixin.fire({
      type: "error",
      title: "Certificado ou senha Inválidos!",
    });
    return false;
  }
};

export const saveConfig = (values, list) => async () => {
  try {
    const config = {
      tipoInscricao: values.typeErollment,
      inscricao: values.enrollment,
      razaoSocial: values.socialReason,
      listaEmpresas: list,
      uf: values.uf,
      senha: values.passCertificate,
    };
    await sentinela.post(`area-clientes/configuracao`, config);
    ToastMixin.fire({
      type: "success",
      title: "Suas configurações foram salvas.",
    });
  } catch (error) {
    ToastMixin.fire({
      type: "error",
      title: "Não foi salvar as configurações.",
    });
  }
};

export const getUnidadesFederativas = async () => {
  try {
    const { data } = await axios.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
    );

    return data.map((estado) => {
      return {
        value: estado.sigla,
        label: estado.sigla,
      };
    });
  } catch (error) {
    ToastMixin.fire({
      type: "error",
      title: "Não foi possível buscar os estados.",
    });
  }
};
