import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const DropdownMenuItems = ({ menuItemsList }) => {
  const dropdowns = menuItemsList
    .map((item, index) => {
      return (
        item.funcaoDescricao !== "Home" && (
          <Dropdown.Item key={index} as={Link} to={item.rota}>
            {item.funcaoDescricao}
          </Dropdown.Item>
        )
      );
    })
    .filter(Boolean);

  dropdowns.push(
    <Dropdown.Item
      key={menuItemsList.length + 1}
      as={Link}
      to={"/alterar-senha"}
    >
      Alterar Senha
    </Dropdown.Item>
  );

  return dropdowns;
};

export default DropdownMenuItems;
