import * as Yup from "yup";

export const AuthFirstAccessSchema = Yup.object().shape({
  codigoCliente: Yup.string()
    .min(8, "Código de Cliente deve conter 8 digitos. Exemplo: 00-00000")
    .required("Campo requerido."),
  senhaSite: Yup.string().required("Campo requerido.")
});

export const NewEmailSchema = Yup.object().shape({
  nome: Yup.string().required("Campo requerido."),
  email: Yup.string()
    .email("O e-mail informado é inválido.")
    .required("Campo requerido.")
});
