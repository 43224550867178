import notify from "devextreme/ui/notify";

const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
}

const createNotification = (message, type) => {
  notify(
    {
      message: message,
      width: 700,
      position: "top"
    },
    type,
    2500
  );
};

export const createSuccessNotification = (message) => createNotification(message, NOTIFICATION_TYPE.SUCCESS);

export const createErrorNotification = (message) => createNotification(message, NOTIFICATION_TYPE.ERROR);

export const createWarningNotification = (message) => createNotification(message, NOTIFICATION_TYPE.WARNING);

export const handleBackendErrorResponse = (response) => {
  // TODO: utilizar optional chaining quando a aplicação estiver em uma versão do React que suporte
  if (!response || !response.data || !response.data.erros) return;

  const errors = response.data.erros;

  if (errors.length === 0) return;

  createErrorNotification(errors[0].mensagem);
}

export default createNotification;
