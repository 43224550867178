import Swal from "sweetalert2";
import _ from "lodash";
import sentinela from "../api/sentinela";
import { history } from "../history";
import { superUsuarioId } from "../utils/Constants";
import { ErrorMessages } from "../utils/ErrorMessages";
import { createErrorNotification, createSuccessNotification } from "../utils/Notify";
import {
  CHANGE_USER_ACCESS_PERMISSIONS_FAIL,
  CHANGE_USER_ACCESS_PERMISSIONS_SUCCESS,
  CREATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  FETCH_CONTRACT_USERS_FAIL,
  FETCH_CONTRACT_USERS_SUCCESS,
  FETCH_CURRENT_USER_FAIL,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_IMPLANTATION_USERS_FAIL,
  FETCH_IMPLANTATION_USERS_SUCCESS,
  FETCH_SELECTED_USER_FAIL,
  FETCH_SELECTED_USER_SUCCESS,
  FETCH_USER_ACCESS_PERMISSIONS_FAIL,
  FETCH_USER_ACCESS_PERMISSIONS_SUCCESS,
  FETCH_USER_ROLE_FAIL,
  FETCH_USER_ROLE_SUCCESS,
  FETCH_USER_TENANTS_FAIL,
  FETCH_USER_TENANTS_SUCCESS,
  GENERATE_NEW_PASSWORD_FAIL,
  GENERATE_NEW_PASSWORD_SUCCESS,
  SET_USER_ADMINISTRATOR_FAIL,
  SET_USER_ADMINISTRATOR_SUCCESS,
  USER_CHANGE_EMAIL_FAIL,
  USER_CHANGE_EMAIL_SUCCESS,
  USER_SEND_EMAIL_CONFIRMATION_FAIL,
  USER_SEND_EMAIL_CONFIRMATION_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
} from "./types";

export const fetchContratcUsers = () => async (dispatch, getState) => {
  const { currentUser } = getState().auth;

  const defineUrlRequest = () => {
    return currentUser.usuarioId === superUsuarioId
      ? "/usuarios"
      : `/contratos/${currentUser.contratoAtual.contratoId}/usuarios`;
  };

  try {
    const urlRequest = defineUrlRequest();
    const { data } = await sentinela.get(urlRequest);

    dispatch({ type: FETCH_CONTRACT_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_CONTRACT_USERS_FAIL });
  }
};

export const fetchImplantationUsers = () => async (dispatch) => {
  try {
    const { data } = await sentinela.get("/usuarios/implantacao");

    dispatch({ type: FETCH_IMPLANTATION_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_IMPLANTATION_USERS_FAIL });
  }
};

export const gerarSenhaAleatoria = (email) => async (dispatch) => {
  try {
    const { value } = await Swal.fire({
      title: "Você tem certeza que deseja resetar?",
      text: "Uma nova senha será gerada para o usuário.",
      type: "warning",
      focusCancel: true,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#18a689",
      confirmButtonText: "Sim, pode gerar!",
    });

    if (!value) return;

    const { data } = await sentinela.put("/usuarios/gerar-senha-aleatoria", {
      usuario: email,
    });

    Swal.fire({
      title: "Credenciais de Acesso",
      type: "info",
      html: `<div><b>Email:</b> ${email}</div><div><b>Senha:</b> ${data.novaSenha}</div>`,
    });

    dispatch({ type: GENERATE_NEW_PASSWORD_SUCCESS });
  } catch (error) {
    dispatch({ type: GENERATE_NEW_PASSWORD_FAIL });
  }
};

export const sendEmailUser = (email) => async (dispatch) => {
  try {
    const { value } = await Swal.fire({
      title: "Você tem certeza que deseja enviar?",
      text:
        "Será enviado um novo e-mail de confirmação para o usuário selecionado.",
      type: "warning",
      focusCancel: true,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#18a689",
      confirmButtonText: "Sim, pode enviar!",
    });

    if (!value) return;

    const { data } = await sentinela.post("/usuarios/reenviar-email", {
      usuario: email,
    });

    createSuccessNotification(data.mensagem);
    dispatch({ type: USER_SEND_EMAIL_CONFIRMATION_SUCCESS });
  } catch ({ response }) {
    createErrorNotification(response.data.mensagem);
    dispatch({ type: USER_SEND_EMAIL_CONFIRMATION_FAIL });
  }
};

export const deletarUsuario = (usuarioId, contratoId) => async (
  dispatch,
  getState
) => {
  try {
    const { value } = await Swal.fire({
      title: "Deseja realmente excluir este cadastro?",
      text:
        "Uma vez excluído, a operação se torna irreversível e o usuário não poderá ter acesso ao ambiente Tron.",
      type: "warning",
      focusCancel: true,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#18a689",
      confirmButtonText: "Sim, pode excluir!",
      cancelButtonText: "Cancelar"
    });

    if (!value) return;

    const { data } = await sentinela.delete(
      `/usuarios/${usuarioId}/${contratoId}`
    );

    const {
      users: { contractUsers },
    } = getState();

    const usuarios = [...contractUsers];

    _.remove(
      usuarios,
      (usuario) =>
        usuario.usuarioId === usuarioId && usuario.contratoId === contratoId
    );

    dispatch({ type: FETCH_CONTRACT_USERS_SUCCESS, payload: usuarios });

    createSuccessNotification(data.mensagem);
    dispatch({ type: USER_DELETE_SUCCESS });
  } catch ({ response }) {
    createErrorNotification(response.data.mensagem);
    dispatch({ type: USER_DELETE_FAIL });
  }
};

export const changeUserEmail = (dadosUsuario) => async (dispatch) => {
  try {
    const { value } = await Swal.fire({
      title: "Você tem certeza que deseja alterar?",
      text:
        "O e-mail será alterado caso ele não esteja relacionado há algum produto.",
      type: "warning",
      focusCancel: true,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#18a689",
      confirmButtonText: "Sim, pode trocar!",
    });

    if (!value) return;

    const { data } = await sentinela.put(
      "/usuarios/alterar-email",
      dadosUsuario
    );

    createSuccessNotification(data.mensagem);
    dispatch({ type: USER_CHANGE_EMAIL_SUCCESS });

    history.push("/usuario");
  } catch ({ response, message }) {
    const errorMessage = response ? response.data.erro[0].chave : message;

    createErrorNotification(ErrorMessages[errorMessage]);
    dispatch({ type: USER_CHANGE_EMAIL_FAIL });
  }
};

export const setUserAdministrator = (usuarioId, contratoId, value) => async (
  dispatch
) => {
  const update = {
    usuarioId,
    contratoId,
    isAdmin: value,
  };

  try {
    await sentinela.put("/perfis-visibilidade/alterar-grupo-contrato", update);
    dispatch({ type: SET_USER_ADMINISTRATOR_SUCCESS });
    dispatch(fetchContratcUsers());
  } catch (erro) {
    dispatch({ type: SET_USER_ADMINISTRATOR_FAIL });
  }
};

export const createUser = (formValues, componentInstace, cb) => async (
  dispatch
) => {
  try {
    const groupedItems = componentInstace.option("selectedItems");

    let verificaTipoFunc;
    switch (Number(formValues.classificacaoContrato)){
      case 1:
        verificaTipoFunc = "Administrador"
        break;
      case 2: 
        verificaTipoFunc = "Colaborador"
        break;
      case 7:
        verificaTipoFunc = "Padrão"
        break;
      default:
        verificaTipoFunc = "Visitante"
    }

    const usuario = {
      ...formValues,
      classificacaoContrato : Number(formValues.classificacaoContrato),
      tenants: [],
      classificacaoFuncionario: verificaTipoFunc,
    };

    groupedItems.forEach(({ items }) => usuario.tenants.push(...items));
    await sentinela.post(`/usuarios`, usuario);

    dispatch({ type: CREATE_USER_SUCCESS });

    if (cb && typeof cb === "function") cb();
  } catch (error) {
    dispatch({ type: CREATE_USER_FAIL });
  }
};

export const addUserToTenant = (usuario, cb) => async (dispatch) => {
  try {
    await sentinela.post(`/usuarios`, usuario);

    dispatch({ type: CREATE_USER_SUCCESS });

    if (cb && typeof cb === "function") cb();
  } catch (error) {
    dispatch({ type: CREATE_USER_FAIL });
  }
};

export const fetchUserTenants = (contratoId, usuarioId) => async (dispatch) => {
  try {
    const { data } = await sentinela.get(
      `/contratos/${contratoId}/usuarios/${usuarioId}/tenants`
    );

    dispatch({ type: FETCH_USER_TENANTS_SUCCESS, payload: data });
  } catch (erro) {
    dispatch({ type: FETCH_USER_TENANTS_FAIL });
  }
};

export const fetchUserRole = (usuarioId, tenantId, cb) => async (dispatch) => {
  try {
    const { data } = await sentinela.get(
      `/usuarios/papel-usuario/${usuarioId}/${tenantId}`
    );
    dispatch({ type: FETCH_USER_ROLE_SUCCESS });
    dispatch(fetchUserAccessPermissions(usuarioId, tenantId));
    if (cb && typeof cb === "function") cb(data.papelUsuario);
  } catch (erro) {
    dispatch({ type: FETCH_USER_ROLE_FAIL });
  }
};

export const fetchUserAccessPermissions = (usuarioId, tenantId, cb) => async (
  dispatch
) => {
  try {
    const { data } = await sentinela.get(
      `/permissoes-acesso/permissoes/${usuarioId}/${tenantId}`
    );
    dispatch({ type: FETCH_USER_ACCESS_PERMISSIONS_SUCCESS, payload: data });
    if (cb && typeof cb === "function") cb(data);
  } catch (erro) {
    dispatch({ type: FETCH_USER_ACCESS_PERMISSIONS_FAIL });
  }
};

export const changePermissionTenant = (
  dataRef,
  data,
  checked,
  usuarioId,
  tenantId
) => async (dispatch) => {
  let modulo = {};
  let funcao = {};
  let operacao = {};

  if (!data) {
    // Será alterado a permissão do módulo, funções e operações.
    modulo = dataRef;
    modulo.moduloPermitido = checked;
    funcao = null;
    operacao = null;
  } else if (dataRef.operacaoId) {
    // Será alterada a permissão da operação.
    operacao = dataRef;
    funcao = data;
    operacao.operacaoPermitida = checked;
    modulo = null;
  } else {
    // Será alterada a permissão da função e suas operações.
    funcao = dataRef;
    operacao = null;
    funcao.funcaoPermitida = checked;
  }
  // Criação do objeto para atender o controlador de permissão.
  const dadosPermissaoAcesso = {
    modulo,
    funcao,
    operacao,
    usuarioId,
    tenantId,
  };

  try {
    await sentinela.put(
      `/permissoes-acesso/alterar-permissao/`,
      dadosPermissaoAcesso
    );
    dispatch(fetchUserAccessPermissions(usuarioId, tenantId));
    dispatch({ type: CHANGE_USER_ACCESS_PERMISSIONS_SUCCESS });
  } catch (err) {
    dispatch({ type: CHANGE_USER_ACCESS_PERMISSIONS_FAIL });
  }
};

export const changePermissionContract = (
  dataRef,
  data,
  checked,
  usuarioId,
  contratoId
) => async (dispatch) => {
  let modulo = {};
  let funcao = {};
  let operacao = {};

  if (!data) {
    // Será alterado a permissão do módulo, funções e operações.
    modulo = dataRef;
    modulo.moduloPermitido = checked;
    funcao = null;
    operacao = null;
  } else if (dataRef.operacaoId) {
    // Será alterada a permissão da operação.
    operacao = dataRef;
    funcao = data;
    operacao.operacaoPermitida = checked;
    modulo = null;
  } else {
    // Será alterada a permissão da função e suas operações.
    funcao = dataRef;
    operacao = null;
    funcao.funcaoPermitida = checked;
  }
  // Criação do objeto para atender o controlador de permissão.
  const dadosPermissaoAcesso = {
    modulo,
    funcao,
    operacao,
    usuarioId,
    contratoId,
  };

  try {
    await sentinela.put(
      `/permissoes-acesso/alterar-permissao/`,
      dadosPermissaoAcesso
    );
    dispatch(fetchUserSelected(contratoId, usuarioId));
    dispatch({ type: CHANGE_USER_ACCESS_PERMISSIONS_SUCCESS });
  } catch (err) {
    dispatch({ type: CHANGE_USER_ACCESS_PERMISSIONS_FAIL });
  }
};

export const changeContract = (value) => (dispatch, getState) => {
  const {
    auth: { currentUser },
  } = getState();

  try {
    const contratoAtual = currentUser.contratos.find(
      (c) => c.contratoId === value
    );
    sessionStorage.setItem("contratoId", value);

    const novoUsuarioLogado = {
      ...currentUser,
      contratoAtual,
    };

    dispatch({ type: FETCH_CURRENT_USER_SUCCESS, payload: novoUsuarioLogado });

    window.location.reload();
  } catch (error) {
    dispatch({ type: FETCH_CURRENT_USER_FAIL });
  }
};

export const fetchUserSelected = (contratoId, usuarioId) => async (
  dispatch
) => {
  try {
    const { data } = await sentinela.get(
      `/usuarios/papeis-usuario/${usuarioId}/${contratoId}`
    );

    dispatch({
      type: FETCH_SELECTED_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: FETCH_SELECTED_USER_FAIL });
  }
};

export const getUser = (usuarioId) => async (dispatch) => {
  try {
    const { data } = await sentinela.get(`/usuarios/${usuarioId}`);

    dispatch({
      type: FETCH_SELECTED_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: FETCH_SELECTED_USER_FAIL });
  }
};
