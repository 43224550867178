import "../AppLayout/styles/AppLayout.css";

import React from "react";

import Footer from "../AppLayout/Footer";

const FirstAccessLayout = ({ children }) => (
  <div className="app-container">
    <header className="app" style={{ height: 45 }} />

    <div className="text-center">
      <img
        src="assets/img/logo-tron-login.png"
        alt="logo_tron"
        style={{ width: 120, marginTop: 25, marginBottom: 10 }}
      />
    </div>

    {children}
    <Footer />
  </div>
);

export default FirstAccessLayout;
