import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './assets/css/styles.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { loadMessages, locale } from 'devextreme/localization';
import ptMessages from 'devextreme/localization/messages/pt.json';

import Router from './components/Router';
import store from './store';

loadMessages(ptMessages);
locale(navigator.language || navigator.browserLanguage);
console.log('LOG DE TESTE');

ReactDOM.render(
  <Provider store={store}>
      <Router />
  </Provider>,
  document.getElementById("root")
);
