import { Drawer } from "antd";
import List from "devextreme-react/list";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { atualizarRotas, changeContract, logout } from "../../actions";
import { superUsuarioId, urlAreaCliente } from "../../utils/Constants";
import DropdownMenuItems from "./DropdownMenuItems";

const NavBar = ({
  menuItems,
  logoutAction,
  changeContractAction,
  routeUpdateAction,
  auth: { currentUser, token },
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const { contratos, contratoAtual, restrictedArea, usuarioId } = currentUser;

  const contratosAdmin = contratos.filter(
    (contrato) => contrato.grupoUsuarioDescricao === "Administrador"
  );

  const renderItem = ({ codigoCliente, razaoSocial }) => (
    <div className="change-contract">
      <span>{`${codigoCliente} - ${razaoSocial}`}</span>
    </div>
  );

  const renderClientAreaLink = () => {
    return (
      <ul className="nav">
        <li className="nav-item">
          <div className="nav-link">
            <a href={`${urlAreaCliente}?accessKey=${token}`}>
              Ir para Área do Cliente
            </a>
          </div>
        </li>
      </ul>
    );
  };


  return (
    <header className="app">
      <div className="container">
        <div className="row">
          <div className="col-3 col-lg-3 d-flex align-items-center">
            <Link className="navbar-brand" to="/">
              <span>Tron</span>
            </Link>
            {restrictedArea && renderClientAreaLink()}
          </div>
          <div className="col-9 col-lg-9 d-flex justify-content-end align-items-center">
            <ul className="nav justify-content-end">
              {contratosAdmin.length > 0 && (
                <li className="nav-item">
                  <span className="nav-link">
                    {`${contratoAtual.codigoCliente} - ${contratoAtual.razaoSocial}`}
                  </span>
                </li>
              )}

              {contratosAdmin.length > 1 && (
                <li className="nav-item">
                  <div className="nav-link" onClick={() => setShowDrawer(true)}>
                    <i className="fa fa-retweet" />
                  </div>
                </li>
              )}

              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" variant="conta">
                  &nbsp;{currentUser.nome}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DropdownMenuItems menuItemsList={menuItems} />
                  <Dropdown.Divider />
           
                  <Dropdown.Divider />
                  {usuarioId === superUsuarioId && (
                    <Dropdown.Item onClick={routeUpdateAction}>
                      Atualizar Rotas
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={logoutAction}>Sair</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </div>
      </div>

      <Drawer
        title="Ambiente"
        placement="right"
        width={500}
        closable={false}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
      >
        <List
          dataSource={contratos}
          itemRender={renderItem}
          onItemClick={({ itemData: { contratoId } }) =>
            changeContractAction(contratoId)
          }
        />
      </Drawer>
    </header>
  );
};

const mapStateToProps = (state) => ({
  menuItems: state.routes.appMenus,
  auth: state.auth,
});

const mapDispatchToProps = {
  logoutAction: logout,
  changeContractAction: changeContract,
  routeUpdateAction: atualizarRotas,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
