import {
  FETCH_CONTRACT_USERS_SUCCESS,
  FETCH_IMPLANTATION_USERS_SUCCESS,
  FETCH_USER_TENANTS_SUCCESS,
  FETCH_USER_ACCESS_PERMISSIONS_SUCCESS,
  FETCH_SELECTED_USER_SUCCESS
} from "../actions/types";

const INITIAL_STATE = {
  implantationUsers: [],
  contractUsers: [],
  userSelected: {},
  userTenants: {},
  userPermissions: null
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONTRACT_USERS_SUCCESS:
      return { ...state, contractUsers: payload };
    case FETCH_IMPLANTATION_USERS_SUCCESS:
      return { ...state, implantationUsers: payload };
    case FETCH_USER_TENANTS_SUCCESS:
      return { ...state, userTenants: payload };
    case FETCH_USER_ACCESS_PERMISSIONS_SUCCESS:
      return { ...state, userPermissions: payload };
    case FETCH_SELECTED_USER_SUCCESS:
      return { ...state, userSelected: payload };
    default:
      return state;
  }
};
