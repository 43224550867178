import React from "react";

const Footer = () => (
  <footer className="app">
    <div className="container">
      <div className="row">
        <div className="col-6 d-flex flex-column flex-md-row align-items-center">
          <div className="app-copyright">
            Copyright Tron Informática © {new Date().getFullYear()}
          </div>
        </div>
        <div className="col-6 d-flex justify-content-end flex-column flex-md-row align-items-center">
          <div className="navbar-brand">
            <span>Tron Área do Cliente</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
