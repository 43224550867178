import {
    CLEAR_APP_ROUTES_MENUS,
    FETCH_APP_ROUTES_MENUS_SUCCESS,
} from '../actions/types';
import FirstAccess from '../components/FirstAccess';
import EmailConfiguration from '../components/FirstAccess/EmailConfiguration';
import ForgotPassword from '../components/ForgotPassword';
import Login from '../components/Login';
import NewPassword from '../components/NewPassword';
import DEFAULT_ROUTE_CONFIG from '../utils/DefaultRouteConf';
import DirectAccess from './../components/DirectAccess';

const FIRST_ACCESS_STATE = [
    {
        ...DEFAULT_ROUTE_CONFIG,
        path: '/primeiro-acesso',
        component: FirstAccess,
    },
    {
        ...DEFAULT_ROUTE_CONFIG,
        path: '/configurar-email',
        component: EmailConfiguration,
    },
];

const INITIAL_STATE = {
    appRoutes: [
        {
            ...DEFAULT_ROUTE_CONFIG,
            path: '/login',
            component: Login,
        },
        {
            ...DEFAULT_ROUTE_CONFIG,
            path: '/informe-email',
            component: ForgotPassword,
        },
        {
            ...DEFAULT_ROUTE_CONFIG,
            path: '/contratos',
            component: ForgotPassword,
        },
        {
            ...DEFAULT_ROUTE_CONFIG,
            path: '/cadastrar-senha',
            component: NewPassword,
        },
        {
            ...DEFAULT_ROUTE_CONFIG,
            path: '/autorizar',
            component: DirectAccess,
        },
        {
            ...DEFAULT_ROUTE_CONFIG,
            path: '/configuracoes',
            component: DirectAccess,
        },
        ...FIRST_ACCESS_STATE,
    ],
    appMenus: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_APP_ROUTES_MENUS_SUCCESS:
            return {
                ...state,
                appRoutes: action.payload.rotas,
                appMenus: action.payload.menus,
            };
        case CLEAR_APP_ROUTES_MENUS:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
