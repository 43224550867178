import { history } from "../history";
import {
  SET_USER_GROUPS_SUCCESS,
  SET_USER_GROUPS_FAIL,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_FAIL,
  CREATE_USER_GROUP_FAIL,
  CREATE_USER_GROUP_SUCCESS,
  FECTH_USER_MODULES_SUCCESS,
  FECTH_USER_MODULES_FAIL,
  FETCH_VISIBILITY_PROFILE_USER_GROUP_FAIL,
  FETCH_VISIBILITY_PROFILE_USER_GROUP_SUCCESS,
  FETCH_USER_GROUP_SUCCESS,
  FETCH_USER_GROUP_FAIL,
  DELETE_VISIBILITY_PROFILE_FAIL,
  DELETE_VISIBILITY_PROFILE_SUCCESS,
  CREATE_VISIBILITY_PROFILE_SUCCESS,
  CREATE_VISIBILITY_PROFILE_FAIL,
  FETCH_USER_GROUP_PERMISSIONS_SUCCESS,
  FETCH_USER_GROUP_PERMISSIONS_FAIL,
  CHANGE_USER_GROUP_PERMISSIONS_SUCCESS,
  CHANGE_USER_GROUP_PERMISSIONS_FAIL
} from "./types";
import sentinela from "../api/sentinela";
import Swal from "sweetalert2";

import { createErrorNotification, createSuccessNotification } from "../utils/Notify";

// TODO: Corrigir as mensagens de erro.
export const getUserGroups = () => async dispatch => {
  try {
    const { data } = await sentinela.get("/grupos-usuarios");

    dispatch({ type: SET_USER_GROUPS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SET_USER_GROUPS_FAIL });
  }
};

// TODO: Corrigir as mensagens de erro.
export const deleteUserGroup = id => async dispatch => {
  try {
    const { data } = await sentinela.delete(`/grupos-usuarios/${id}`);

    createSuccessNotification(data.mensagem);
    dispatch({ type: DELETE_USER_GROUP_SUCCESS, payload: id });
  } catch ({ response }) {
    createErrorNotification(response.data.mensagem);
    dispatch({ type: DELETE_USER_GROUP_FAIL });
  }
};

// TODO: Corrigir as mensagens de erro.
export const createUserGroup = formValues => async dispatch => {
  try {
    const { data } = await sentinela.post("/grupos-usuarios", formValues);

    createSuccessNotification(data.mensagem);
    dispatch({ type: CREATE_USER_GROUP_SUCCESS });

    history.push("/grupo-usuario");
  } catch ({ response }) {
    createErrorNotification(response.data.mensagem);
    dispatch({ type: CREATE_USER_GROUP_FAIL });
  }
};

// TODO: Corrigir as mensagens de erro.
export const fetchModules = () => async (dispatch, getState) => {
  const {
    auth: {
      currentUser: { usuarioId }
    }
  } = getState();

  try {
    const { data } = await sentinela.get(`/modulos/usuarios/${usuarioId}`);

    dispatch({ type: FECTH_USER_MODULES_SUCCESS, payload: data.modulos });
  } catch ({ response }) {
    createErrorNotification(response.data.mensagem);
    dispatch({ type: FECTH_USER_MODULES_FAIL });
  }
};

// TODO: Corrigir as mensagens de erro.
export const fetchUserGroup = id => async dispatch => {
  try {
    const { data } = await sentinela.get(`/grupos-usuarios/${id}`);

    dispatch({
      type: FETCH_USER_GROUP_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: FETCH_USER_GROUP_FAIL });
  }
};

// TODO: Corrigir as mensagens de erro.
export const fetchUserGroupVisibilityProfile = grupoUsuarioId => async dispatch => {
  try {
    const { data } = await sentinela.get(
      `/perfis-visibilidade/grupos-usuario/${grupoUsuarioId}`
    );

    dispatch({
      type: FETCH_VISIBILITY_PROFILE_USER_GROUP_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: FETCH_VISIBILITY_PROFILE_USER_GROUP_FAIL });
  }
};

// TODO: Corrigir as mensagens de erro.
export const deleteVisibilityProfile = id => async dispatch => {
  try {
    const { value } = await Swal.fire({
      title: "Você tem certeza?",
      text:
        "Ao confirmar você apagará todas as configurações deste perfil e não será possível recupera-las!",
      type: "warning",
      focusCancel: true,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#18a689",
      confirmButtonText: "Sim, pode excluir!"
    });

    if (!value) return;

    const { data } = await sentinela.delete(`/perfis-visibilidade/${id}`);

    createSuccessNotification(data.mensagem);
    dispatch({ type: DELETE_VISIBILITY_PROFILE_SUCCESS, payload: id });
  } catch ({ response }) {
    createErrorNotification(response.data.mensagem);
    dispatch({ type: DELETE_VISIBILITY_PROFILE_FAIL });
  }
};

// TODO: Corrigir as mensagens de erro.
export const createVisibilityProfile = (
  formValues,
  grupoUsuarioId
) => async dispatch => {
  try {
    const { data } = await sentinela.post("/perfis-visibilidade", {
      ...formValues,
      grupoUsuarioId,
      tipoPerfil: 3
    });

    createSuccessNotification(data.mensagem);
    dispatch({ type: CREATE_VISIBILITY_PROFILE_SUCCESS });

    history.push(`/grupo-usuario/editar/${grupoUsuarioId}`);
  } catch ({ response }) {
    createErrorNotification(response.data.mensagem);
    dispatch({ type: CREATE_VISIBILITY_PROFILE_FAIL });
  }
};

// TODO: Corrigir as mensagens de erro.
export const getVisibilityProfileById = id => async dispatch => {
  try {
    const { data } = await sentinela.get(`/perfis-visibilidade/${id}`);

    dispatch({ type: FETCH_USER_GROUP_PERMISSIONS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_USER_GROUP_PERMISSIONS_FAIL });
  }
};

export const changePermissionUserGroup = (
  dataRef,
  data,
  checked,
  perfilVisibilidadeId
) => async dispatch => {
  let modulo = {};
  let funcao = {};
  let operacao = {};

  // Será alterado a permissão do módulo, funções e operações.
  if (!data) {
    modulo = dataRef;
    modulo.moduloPermitido = checked;
    funcao = null;
    operacao = null;
  } else if (dataRef.operacaoId) {
    // Será alterada a permissão da operação.
    operacao = dataRef;
    funcao = data;
    operacao.operacaoPermitida = checked;
    modulo = null;
  } else {
    // Será alterada a permissão da função e suas operações.
    funcao = dataRef;
    operacao = null;
    funcao.funcaoPermitida = checked;
  }

  // Criação do objeto para atender o controlador de permissão.
  const dadosPermissaoAcesso = {
    modulo,
    funcao,
    operacao,
    perfilVisibilidadeId
  };

  try {
    await sentinela.put(
      `/perfis-visibilidade/alterar-visibilidade`,
      dadosPermissaoAcesso
    );
    dispatch(getVisibilityProfileById(perfilVisibilidadeId));
    dispatch({ type: CHANGE_USER_GROUP_PERMISSIONS_SUCCESS });
  } catch (err) {
    dispatch({ type: CHANGE_USER_GROUP_PERMISSIONS_FAIL });
  }
};
