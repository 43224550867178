import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchBanners } from "../../actions";

const Banner = ({ bannerList, fetchBannersAction }) => {
  useEffect(() => {
    if (bannerList.length > 0) return;
    fetchBannersAction();
  }, [fetchBannersAction, bannerList.length]);

  const renderImage = (imagePath, key) => (
    <div key={key} className="wrap-banner-login">
      <img src={imagePath} alt="banner_access" className="responsivando" />
    </div>
  );

  return (
    <div className="col-xl-4 offset-xl-2 text-center order-xl-1">
      <div className="vert-align-banners">
        {bannerList.map((banner, index) => {
          if (banner.redirectLink)
            return (
              <a
                href={banner.redirectLink}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                {renderImage(banner.imagePath, index)}
              </a>
            );

          return renderImage(banner.imagePath, index);
        })}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  bannerList: state.banners.bannerList
});

export default connect(
  mapStateToProps,
  { fetchBannersAction: fetchBanners }
)(Banner);
