import axios from 'axios';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const sentinela = () => {
  const defaultOptions = {
    baseURL,
    headers: {
      "Content-Type": "application/json"
    }
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(config => {
    const token = sessionStorage.getItem("token");
    const contratoId = sessionStorage.getItem("contratoId");

    config.headers.Authorization = token ? `Bearer ${token}` : "";
    config.headers.ContractIdentify = contratoId || "";

    return config;
  });

  instance.interceptors.response.use(
    response => response,
    err => {
      if (err.response.status === 401) {
        sessionStorage.clear();
        return window.location.assign("/login");
      }

      return Promise.reject(err);
    }
  );

  return instance;
};

export default sentinela();
