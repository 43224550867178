const jwtDecode = require('jwt-decode');

const tokenIsExpired = token => {
  const now = new Date().getTime() / 1000;
  try {
    return token ? now > jwtDecode(token).exp : true;
  } catch (err) {
    return true;
  }
};

export default tokenIsExpired;
