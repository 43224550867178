import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { ForgotPasswordSchema } from "../../utils/validations";

const ForgotPasswordForm = ({ onSubmit, ...props }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{ usuario: "" }}
    validationSchema={ForgotPasswordSchema}
  >
    {props => (
      <form onSubmit={props.handleSubmit}>
        <div className="form-group">
          <label htmlFor="usuario">Endereço de e-mail:</label>
          <Field
            name="usuario"
            type="input"
            placeholder="Digite seu e-mail"
            className="form-control tron-input"
          />
          <ErrorMessage
            className="validation-errors pt-2"
            component="div"
            name="usuario"
          />
        </div>

        <button type="submit" className="btn btn-block btn-tron">
          Enviar
        </button>
      </form>
    )}
  </Formik>
);

export default ForgotPasswordForm;
