import { PropTypes } from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

const propTypes = {
  children: PropTypes.element.isRequired
};

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.portalElem = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.portalElem);
  }

  componentWillUnmount() {
    document.body.removeChild(this.portalElem);
  }

  render() {
    const { children } = this.props;

    return ReactDOM.createPortal(children, this.portalElem);
  }
}

Portal.propTypes = propTypes;

export default Portal;
