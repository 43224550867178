import qs from "query-string";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  createNewPassword,
  setErrorToken,
  forgotPassword,
} from "../../actions";
import Container from "../AuthLayout/Container";
import NewPasswordForm from "./NewPasswordForm";

class NewPassword extends React.Component {
  _obURL = process.env.REACT_APP_ONBOARDING_ENDPOINT;
  
  constructor(props) {
    super(props);

    const { newRegister, module } = qs.parse(props.location.search);

    this.state = {
      isPasswordChanged: false,
      token: null,
      usuario: null,
      module: (module || "SE").trim().toLocaleUpperCase(),
      newRegister: (newRegister || "").toLocaleLowerCase() === "true",
      isLoadingPasswordChange: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.renderText = this.renderText.bind(this);
  }

  componentWillUnmout() {
    this.setState({
      isPasswordChanged: false,
      isEmailSent: false,
      token: null,
      usuario: null,
      module: "SE",
      newRegister: false,
    });
    this.props.setErrorToken(false);
  }

  onSubmit(formValues) {
    const { location, createNewPassword } = this.props;
    const { token, email: usuario } = qs.parse(location.search);
    this.setState({
      token,
      usuario,
      isLoadingPasswordChange: true,
    });

    createNewPassword({ ...formValues, token, usuario }, (status) => {
      if (status === "success") {
        this.setState({
          isPasswordChanged: true,
          isLoadingPasswordChange: false,
        });
      } else {
        this.setState({ isLoadingPasswordChange: false });
      }
    });
  }

  onSendNewToken(usuario) {
    const { forgotPassword } = this.props;

    forgotPassword({ usuario }, () => this.setState({ isEmailSent: true }));
  }

  renderText() {
    const { isPasswordChanged, newRegister } = this.state;

    let text = "";

    if (isPasswordChanged) {
      text = newRegister
        ? "Sua senha foi definida com sucesso. Clique no link abaixo para acessar o site e realizar o login."
        : "Sua senha foi alterada com sucesso. Clique no link abaixo para acessar o site e realizar o login.";
    } else {
      text = newRegister
        ? "Informe a senha que será usada para acessar a sua conta da Tron."
        : "Informe a nova senha que será usada para acessar a sua conta da Tron.";
    }

    return <p>{text}</p>;
  }

  render() {
    const { createPasswordError, showButtonError } = this.props;
    const { isPasswordChanged, isEmailSent, usuario, module, newRegister } = this.state;

    return (
      <Container subTitle={this.renderText}>
        {createPasswordError && (
          <Fragment>
            <div className="alert alert-danger" role="alert">
              {createPasswordError}
            </div>

            {showButtonError && (
              <button
                type="button"
                className="btn btn-block btn-outline-success"
                style={{ marginBottom: 5 }}
                onClick={() => this.onSendNewToken(usuario)}
              >
                Clique aqui para enviar um novo token
              </button>
            )}
          </Fragment>
        )}

        {isEmailSent && (
          <div className="alert alert-success" role="alert">
            Foi enviado um novo e-mail para redefinir a senha.
          </div>
        )}

        {!isPasswordChanged ? (
          <NewPasswordForm
            onSubmit={this.onSubmit}
            isLoading={this.state.isLoadingPasswordChange}
            newRegister={newRegister}
          />
        ) : (
          <ul className="links-footer">
            <li>
              {
                module === "OB"
                    ? <a href={this._obURL} rel="noopener noreferrer" className="btn btn-block btn-link">Fazer login</a>
                    : <Link to="/login" className="btn btn-block btn-link">Fazer login</Link>
              }
            </li>
          </ul>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  createPasswordError: state.auth.createPasswordError,
  showButtonError: state.auth.showButtonError,
});

export default connect(mapStateToProps, {
  createNewPassword,
  setErrorToken,
  forgotPassword,
})(NewPassword);
