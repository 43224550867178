import * as Yup from "yup";

const StepOneSchema = Yup.object().shape({
  modulo: Yup.string().required("Campo requerido."),
});

const StepTwoSchema = Yup.object().shape({
  tipoInscricao: Yup.string().notRequired(),
  inscricao: Yup.mixed()
    .when("tipoInscricao", {
      is: "1",
      then: Yup.string()
        .min(14, "CPF deve conter 11 digitos")
        .required("Campo requerido."),
    })
    .when("tipoInscricao", {
      is: "2",
      then: Yup.string()
        .min(18, "CNPJ deve conter 14 digitos")
        .required("Campo requerido."),
    })
    .when("tipoInscricao", {
      is: "3",
      then: Yup.string()
        .min(18, "CAEPF deve conter 14 digitos")
        .required("Campo requerido."),
    }),
  nomeRazaoSocial: Yup.string()
    .required("Campo requerido.")
    .min(3, "Nome / Razão Social deve conter no Mínimo de 3 caracteres."),
});

export const TenantSchema = [StepOneSchema, StepTwoSchema];

export const TenantConfigSchema = Yup.object().shape({
  nome: Yup.string().required("Campo requerido."),
});
