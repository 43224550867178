import qs from "query-string";

import sentinela from "../api/sentinela";
import { superUsuarioId, urlAreaCliente } from "../utils/Constants";
import { ErrorMessages } from "./../utils/ErrorMessages";
import { clearRoutesMenus, fetchRoutesMenus } from "./routeActions";

import {
  CHANGE_USER_PASSWORD_FAIL,
  CHANGE_USER_PASSWORD_SUCCESS,
  CLIENT_AUTH_FAIL,
  CLIENT_AUTH_SUCCESS,
  CLIENT_LOGOUT_FAIL,
  CLIENT_LOGOUT_SUCCESS,
  CLIENT_RESTRICTED_AREA_FAIL,
  CREATE_NEW_PASSWORD_FAIL,
  CREATE_NEW_PASSWORD_SUCCESS,
  CREATE_NEW_PASSWORD_TOKEN_FAIL,
  FETCH_CURRENT_USER_FAIL,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_MODULOS_CUSTOM_FAIL,
  FETCH_MODULOS_CUSTOM_SUCCESS,
  REQUEST_RECOVERY_PASSWORD_FAIL,
  REQUEST_RECOVERY_PASSWORD_SUCCESS,
  SET_ACCESS_APP_LINK,
  USER_SET_INITIAL_STATE,
} from "./types";

export const setInitialState = () => ({
  type: USER_SET_INITIAL_STATE,
});

export const setErrorToken = (payload) => ({
  type: CREATE_NEW_PASSWORD_TOKEN_FAIL,
  payload,
});

// TODO melhorar o codigo
export const fetchModulosUser = (reload) => async (dispatch, getState) => {
  try {
    const {
      auth: { currentUser, token },
    } = getState();

    const { data } = await sentinela.get("/modulos/usuario?filterTenantsByContract=true");

    const regex = /DB-(GC|TS|CN|LR|BX)-[0-9]/i;

    if (!reload && regex.test(currentUser.nome)) {
      const [tenant] = currentUser.tenants;

      const modulo = data.find(
        (modulo) => modulo.codigoModulo === tenant.tenantModulo
      );

      return window.location.assign(
        `${modulo.link}?accessKey=${token}&tenant=${tenant.tenantId}&redirect=/`
      );
    }

    dispatch({ type: FETCH_MODULOS_CUSTOM_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_MODULOS_CUSTOM_FAIL });
  }
};

export const loadUserData = () => async (dispatch) => {
  await dispatch(fetchCurrentUser());
  await dispatch(fetchRoutesMenus());
  await dispatch(fetchModulosUser());
};

export const fetchCurrentUser = () => async (dispatch, getState) => {
  const {
    auth: { token },
  } = getState();
  try {
    const { data } = await sentinela.get("/usuarios/usuario-logado");

    try {
      const { data: restrictedArea } = await sentinela.get(
        "/area-clientes/permitido"
      );

      // TODO remover essa parte do codigo para outra funcao
      if (!sessionStorage.getItem("contratoId")) {
        const contrato =
          data.contratos.find(
            (c) => c.grupoUsuarioDescricao === "Administrador"
          ) || data.contratos[0];

        if (contrato) sessionStorage.setItem("contratoId", contrato.contratoId);

        if (restrictedArea && process.env.REACT_APP_REDIRECIONAR === "true")
          return window.location.assign(`${urlAreaCliente}?accessKey=${token}`);
      }

      const currentUser = {
        ...data,
        contratoAtual: data.contratos.find(
          (contrato) =>
            contrato.contratoId === sessionStorage.getItem("contratoId")
        ),
        restrictedArea,
      };

      dispatch({ type: FETCH_CURRENT_USER_SUCCESS, payload: currentUser });
    } catch (error) {
      dispatch({ type: CLIENT_RESTRICTED_AREA_FAIL });
    }
  } catch (err) {
    dispatch({ type: FETCH_CURRENT_USER_FAIL });
  }
};

export const login = (credentials, params, cb) => async (dispatch) => {
  const {
    redirectURL,
    toLocation,
    fromApplication,
    tenant,
    customParam,
  } = qs.parse(params);

  try {
    const { data } = await sentinela.post("/autenticacao/login", credentials);
    sessionStorage.setItem("token", data.token);

    if (customParam) {
      return window.location.assign(
        `${redirectURL}?${customParam}=${data.token}`
      );
    }

    // TODO extrair para uma outra função
    if (fromApplication) {
      const tenants = data.tenants.filter(
        (tenant) => tenant.modulo === Number(fromApplication)
      );

      if (tenant && tenants.find((c) => c.tenantId === tenant)) {
        return redirectToApplication(
          redirectURL,
          data.token,
          tenant,
          fromApplication,
          toLocation
        );
      } else if (tenants.length === 1) {
        const [baseDados] = tenants;

        return redirectToApplication(
          redirectURL,
          data.token,
          baseDados.tenantId,
          fromApplication,
          toLocation
        );
      }
    }

    dispatch({ type: CLIENT_AUTH_SUCCESS, payload: data.token });
    dispatch(loadUserData());
    if (cb && typeof cb === "function") cb();
  } catch (err) {
    const { response } = err;
    const errorMessage = response ? response.data.mensagem : err.message;

    dispatch({ type: CLIENT_AUTH_FAIL, payload: errorMessage });
  }
};

export const forgotPassword = (usuario, module, cb) => async (dispatch) => {
  try {
    await sentinela.post(`/usuarios/resetar-senha?module=${module}`, usuario);
    dispatch({ type: REQUEST_RECOVERY_PASSWORD_SUCCESS });
    if (cb && typeof cb === "function") cb();
  } catch ({ response, message }) {
    const errorMessage = response ? response.data.erro[0].chave : message;

    dispatch({
      type: REQUEST_RECOVERY_PASSWORD_FAIL,
      payload: ErrorMessages[errorMessage],
    });
  }
};

// TODO definir uma maneira melhor de pegar as mensagens em um array
export const createNewPassword = (payload, cb) => async (dispatch) => {
  try {
    await sentinela.post("/usuarios/cadastrar-senha", payload);
    dispatch({ type: CREATE_NEW_PASSWORD_SUCCESS });
    if (cb && typeof cb === "function") cb('success');
  } catch (err) {
    const { response } = err;
    const errorMessage = response ? response.data.erro[0].chave : err.message;

    if (errorMessage === "InvalidToken") dispatch(setErrorToken(true));
    if (cb && typeof cb === "function") cb('error');

    return dispatch({
      type: CREATE_NEW_PASSWORD_FAIL,
      payload: ErrorMessages[errorMessage],
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    await sentinela.post("/autenticacao/logout");
    sessionStorage.clear();
    dispatch(clearRoutesMenus());
    dispatch({ type: CLIENT_LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: CLIENT_LOGOUT_FAIL });
  }
};

export const loadTenant = (tenant, modulo) => async (dispatch, getState) => {
  const {
    auth: { token, currentUser },
  } = getState();

  let redirect = "/";

  const defineAccessLink = (redirect) => {
    return `${modulo.link}?accessKey=${token}&tenant=${tenant}&redirect=${redirect}`;
  };

  if (modulo.codigoModulo === 1) {
    if (currentUser.tipo === "Cliente") redirect = "/solicitacoes";
    else if (currentUser.usuarioId === superUsuarioId) redirect = "/gestao";
    else redirect = "/home";
  }

  dispatch({ type: SET_ACCESS_APP_LINK, payload: defineAccessLink(redirect) });
};

// TODO definir uma maneira melhor de pegar as mensagens em um array
export const changePassword = (credenciais) => async (dispatch) => {
  try {
    const { data } = await sentinela.post(
      "/usuarios/alterar-senha",
      credenciais
    );

    const responseMessage = { success: data.sucesso, message: data.mensagem };

    dispatch({
      type: CHANGE_USER_PASSWORD_SUCCESS,
      payload: responseMessage,
    });
  } catch (error) {
    const { response } = error;
    const errorMessage = response
      ? response.data.erro[0].mensagem
      : error.message;

    const responseMessage = {
      success: false,
      message: errorMessage,
    };

    dispatch({
      type: CHANGE_USER_PASSWORD_FAIL,
      payload: responseMessage,
    });
  }
};

const redirectToApplication = (
  redirectURL,
  token,
  tenantId,
  fromApplication,
  toLocation
) => {
  sessionStorage.setItem("applicationModule", fromApplication);
  sessionStorage.setItem("tenant", tenantId);

  return window.location.assign(
    `${redirectURL}/#/acesso-app?accessKey=${token}&tenant=${tenantId}&redirect=${toLocation}`
  );
};
