import "./styles/AuthLayout.css";

import React from "react";

import Header from "./Header";

const AuthLayout = ({ children }) => (
  <div className="container-bg">
    <Header />
    {children}
  </div>
);

export default AuthLayout;
