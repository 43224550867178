import React from "react";
import { Formik, Field, ErrorMessage } from "formik";

import { NewEmailSchema } from "../../utils/validations";

const NewEmailForm = ({ onSubmit }) => (
  <div className="row" style={{ marginTop: 5 }}>
    <div className="col-lg-12">
      <Formik
        onSubmit={onSubmit}
        initialValues={{ nome: "", email: "" }}
        validationSchema={NewEmailSchema}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="codigoCliente">Adicionar novos usuários</label>

              <div className="row">
                <div className="col-lg-6">
                  <Field
                    name="nome"
                    type="text"
                    placeholder="Informe o nome"
                    className="form-control tron-input"
                  />
                  <ErrorMessage
                    className="validation-errors pt-2"
                    component="div"
                    name="nome"
                  />
                </div>

                <div className="col-lg-6">
                  <div className="input-group mb-3">
                    <Field
                      name="email"
                      type="email"
                      placeholder="Informe o e-mail"
                      className="form-control tron-input"
                    />
                    <ErrorMessage
                      className="validation-errors pt-2"
                      component="div"
                      name="email"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-outline-info" type="submit">
                        <i className="fa fa-plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  </div>
);

export default NewEmailForm;
