import {
  CLIENT_AUTH_SUCCESS,
  CLIENT_AUTH_FAIL,
  CREATE_NEW_PASSWORD_FAIL,
  CREATE_NEW_PASSWORD_SUCCESS,
  FETCH_CURRENT_USER_SUCCESS,
  CLIENT_LOGOUT_SUCCESS,
  FETCH_MODULOS_CUSTOM_SUCCESS,
  SET_ACCESS_APP_LINK,
  CHANGE_USER_PASSWORD_FAIL,
  CHANGE_USER_PASSWORD_SUCCESS,
  USER_SET_INITIAL_STATE,
  CREATE_NEW_PASSWORD_TOKEN_FAIL,
  REQUEST_RECOVERY_PASSWORD_FAIL
} from "../actions/types";

const INITIAL_STATE = {
  token: sessionStorage.getItem("token") || null,
  authError: null,
  createPasswordError: null,
  showButtonError: false,
  currentUser: null,
  modules: null,
  accessAppLink: null,
  changePasswordMessage: null,
  forgetEmailMessage: null
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_AUTH_SUCCESS:
      return { ...state, token: payload, authError: null };
    case CLIENT_AUTH_FAIL:
      return { ...state, authError: payload };
    case CREATE_NEW_PASSWORD_SUCCESS:
      return { ...state, createPasswordError: null };
    case CREATE_NEW_PASSWORD_FAIL:
      return { ...state, createPasswordError: payload };
    case CREATE_NEW_PASSWORD_TOKEN_FAIL:
      return { ...state, showButtonError: payload };
    case FETCH_CURRENT_USER_SUCCESS:
      return { ...state, currentUser: payload };
    case CLIENT_LOGOUT_SUCCESS:
      return { ...state, token: null };
    case FETCH_MODULOS_CUSTOM_SUCCESS:
      return { ...state, modules: payload };
    case SET_ACCESS_APP_LINK:
      return { ...state, accessAppLink: payload };
    case CHANGE_USER_PASSWORD_FAIL:
      return { ...state, changePasswordMessage: payload };
    case CHANGE_USER_PASSWORD_SUCCESS:
      return { ...state, changePasswordMessage: payload };
    case USER_SET_INITIAL_STATE:
      return { ...state, changePasswordMessage: null };
    case REQUEST_RECOVERY_PASSWORD_FAIL:
      return { ...state, forgetEmailMessage: payload };
    default:
      return state;
  }
};
