import React from "react";

const StepAction = ({
  loading,
  currentStep,
  nextStep,
  prevStep,
  stepsSize
}) => (
  <div className="steps-action">
    {currentStep < stepsSize - 2 && (
      <button
        type="button"
        className="btn btn-tron-app"
        onClick={nextStep}
        disabled={loading}
      >
        Próximo
      </button>
    )}
    {currentStep === stepsSize - 2 && currentStep < 3 && (
      <button
        type="button"
        className="btn btn-tron-app"
        onClick={nextStep}
        disabled={loading}
      >
        Salvar
      </button>
    )}
    {currentStep > 0 && currentStep < 3 && (
      <button
        className="btn btn-tron-ghost"
        style={{ marginLeft: 8 }}
        onClick={prevStep}
      >
        Anterior
      </button>
    )}
  </div>
);

export default StepAction;
