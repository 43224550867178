import {
  ERP_REQUEST_GET_EMAILS_ERP_SUCCESS,
  ERP_SET_FIRST_ACCESS,
  ERP_SET_CLEAR_TOKEN,
  ERP_CHANGE_CURRENT_STEP,
  ERP_REQUEST_GET_EMAILS_ERP_FAIL,
  ERP_SET_GRID_INSTANCE,
  ERP_SET_TOKEN_SENTINELA,
  ERP_SET_ADMINISTRATOR_EMAILS,
  ERP_SET_CODIGO_CLIENTE,
  ERP_SET_CONTRACT_ADMINISTRATORS
} from "../actions/types";

const INITIAL_STATE = {
  tokenSentinela: null,
  dadosContrato: {},
  emails: [],
  administratorEmails: [],
  authError: null,
  firstAccess: false,
  currentStep: 0,
  gridInstance: null,
  contractAdministrators: []
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ERP_SET_CLEAR_TOKEN:
      return { ...INITIAL_STATE, firstAccess: true };
    case ERP_SET_FIRST_ACCESS:
      return { ...INITIAL_STATE, firstAccess: payload };
    case ERP_CHANGE_CURRENT_STEP:
      return { ...state, currentStep: payload };
    case ERP_SET_TOKEN_SENTINELA:
      return { ...state, tokenSentinela: payload };
    case ERP_SET_CODIGO_CLIENTE:
      return { ...state, dadosContrato: payload };
    case ERP_REQUEST_GET_EMAILS_ERP_SUCCESS:
      return { ...state, emails: payload, authError: null };
    case ERP_SET_ADMINISTRATOR_EMAILS:
      return { ...state, administratorEmails: payload, authError: null };
    case ERP_REQUEST_GET_EMAILS_ERP_FAIL:
      return { ...state, authError: payload };
    case ERP_SET_GRID_INSTANCE:
      return { ...state, gridInstance: payload };
    case ERP_SET_CONTRACT_ADMINISTRATORS:
      return { ...state, contractAdministrators: payload };
    default:
      return state;
  }
};
