import { superUsuarioId } from "../utils/Constants";
import sentinela from "../api/sentinela";
import {
  FETCH_MODULES_BY_CONTRACT_SUCCESS,
  FETCH_MODULES_BY_CONTRACT_FAIL
} from "./types";

export const fetchModulesByContract = () => async (dispatch, getState) => {
  const { currentUser } = getState().auth;

  try {
    const contratoId =
      currentUser.usuarioId !== superUsuarioId
        ? currentUser.contratoAtual.contratoId
        : 1;

    const { data } = await sentinela.get(`/contratos/${contratoId}/modulos`);

    dispatch({ type: FETCH_MODULES_BY_CONTRACT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_MODULES_BY_CONTRACT_FAIL });
  }
};
