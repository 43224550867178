import React from "react";
import { connect } from "react-redux";
import AuthLayout from "../AuthLayout";
import AppLayout from "../AppLayout";
import FirstAccessLayout from "../FirstAccessLayout";
import { loadUserData } from "../../actions";
import Loading from "../Loading";

// TODO analisar o que estes componentes tem em comum para serem reaproveitados
class ContentWrapper extends React.PureComponent {
  async componentDidMount() {
    const { loadUserData, currentUser, token } = this.props;
    if (token && !currentUser) {
      await loadUserData();
    }
  }

  renderAppLayout(children) {
    const { currentUser, menus } = this.props;

    if (!currentUser || !menus.length) return <Loading />;
    return <AppLayout>{children}</AppLayout>;
  }

  renderAuthLayout(children) {
    return <AuthLayout>{children}</AuthLayout>;
  }

  render() {
    const { children, token, firstAccess } = this.props;

    if (firstAccess) return <FirstAccessLayout>{children}</FirstAccessLayout>;

    return (
      <React.Fragment>
        {token
          ? this.renderAppLayout(children)
          : this.renderAuthLayout(children)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  currentUser: state.auth.currentUser,
  menus: state.routes.appMenus,
  firstAccess: state.erp.firstAccess
});

export default connect(
  mapStateToProps,
  { loadUserData }
)(ContentWrapper);
