import {
  SET_USER_GROUPS_SUCCESS,
  SET_USER_GROUPS_FAIL,
  DELETE_USER_GROUP_SUCCESS,
  FECTH_USER_MODULES_SUCCESS,
  FECTH_USER_MODULES_FAIL,
  FETCH_VISIBILITY_PROFILE_USER_GROUP_SUCCESS,
  FETCH_VISIBILITY_PROFILE_USER_GROUP_FAIL,
  FETCH_USER_GROUP_SUCCESS,
  FETCH_USER_GROUP_FAIL,
  DELETE_VISIBILITY_PROFILE_SUCCESS,
  FETCH_USER_GROUP_PERMISSIONS_SUCCESS
} from "../actions/types";

const INITIAL_STATE = {
  groups: [],
  modules: [],
  profiles: [],
  userGroup: {},
  userGroupPermission: null
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_GROUPS_SUCCESS:
      return { ...state, groups: payload };
    case SET_USER_GROUPS_FAIL:
      return { ...state, groups: [] };
    case FECTH_USER_MODULES_SUCCESS:
      return { ...state, modules: payload };
    case FECTH_USER_MODULES_FAIL:
      return { ...state, modules: [] };
    case FETCH_VISIBILITY_PROFILE_USER_GROUP_SUCCESS:
      return { ...state, profiles: payload };
    case FETCH_VISIBILITY_PROFILE_USER_GROUP_FAIL:
      return { ...state, profiles: [] };
    case FETCH_USER_GROUP_SUCCESS:
      return { ...state, userGroup: payload };
    case FETCH_USER_GROUP_FAIL:
      return { ...state, userGroup: {} };
    case FETCH_USER_GROUP_PERMISSIONS_SUCCESS:
      return { ...state, userGroupPermission: payload };
    case DELETE_USER_GROUP_SUCCESS:
      return {
        ...state,
        groups: state.groups.filter(item => item.id !== payload)
      };
    case DELETE_VISIBILITY_PROFILE_SUCCESS:
      return {
        ...state,
        profiles: state.profiles.filter(item => item.id !== payload)
      };
    default:
      return state;
  }
};
