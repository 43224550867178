import { FETCH_BANNERS_SUCCESS } from "../actions/types";
  
  const INITIAL_STATE = {
    bannerList: []
  };
  
  export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case FETCH_BANNERS_SUCCESS:
        return { ...state, bannerList: payload };
      default:
        return state;
    }
  };