import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { NewPasswordSchema } from "../../utils/validations";

const NewPasswordForm = ({ onSubmit, isLoading, newRegister, ...props }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{ senha: "", senhaConfirmar: "" }}
    validationSchema={NewPasswordSchema}
  >
    {(props) => (
      <form onSubmit={props.handleSubmit}>
        <div className="form-group">
          <label htmlFor="novaSenha">Senha:</label>
          <Field
            name="senha"
            type="password"
            placeholder={newRegister ? "Digite a senha" : "Digite a nova senha"}
            className="form-control tron-input"
          />
          <ErrorMessage
            className="validation-errors pt-2"
            component="div"
            name="senha"
          />
        </div>
        <div className="form-group">
          <label htmlFor="senhaConfirmar">Confirmar Senha:</label>
          <Field
            name="senhaConfirmar"
            type="password"
            placeholder={
              newRegister ? "Confirme a senha" : "Confirme a nova senha"
            }
            className="form-control tron-input"
          />
          <ErrorMessage
            className="validation-errors pt-2"
            component="div"
            name="senhaConfirmar"
          />
        </div>

        <button
          type="submit"
          className="btn btn-block btn-tron"
          disabled={isLoading}
        >
          {isLoading
            ? "Carregando..."
            : newRegister
            ? "Definir senha"
            : "Alterar senha"}
        </button>
      </form>
    )}
  </Formik>
);

export default NewPasswordForm;
