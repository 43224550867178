import "./styles/AppLayout.css";

import React from "react";

import NavBar from "./NavBar";
import Footer from "./Footer";

// TODO evitar essas chamadas aqui no component, tentar armazenar isso pelo menos o currentUser;
class AppLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="app-container">
        <NavBar />
        {children}
        <Footer />
      </div>
    );
  }
}

export default AppLayout;
