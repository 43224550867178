import React from "react";
import Lottie from "react-lottie";
import animation from "./loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

class Loading extends React.Component {
  render() {
    return (
      <div
        className={this.props.size === 'small' ? 'small-loading-container' : 'loading-container'}
      >
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }
}

export default Loading;
